<template>
  <base-wrapper class="space-y-4">
    <base-card title="Tambah Saldo Deposit SC">
      <form class="grid gap-4 sm:grid-cols-6" @submit.prevent="onSubmit">
        <div class="sm:col-span-6">
          <base-input inset with-label label="Tanggal" disabled :value="date" />
        </div>
        <div class="sm:col-span-3">
          <search-office-input v-model="office" />
        </div>
        <div class="sm:col-span-3">
          <base-input
            id="nama_stockist_center"
            inset
            with-label
            label="Nama Stockist Center"
            placeholder="SC1"
            disabled
            :value="office.name"
          />
        </div>
        <div class="sm:col-span-2">
          <base-input
            id="nominal"
            inset
            with-label
            label="Nominal Topup"
            placeholder="100.000"
            type="text"
            currency
            v-model="amount"
          />
        </div>
        <div class="sm:col-span-2">
          <base-input
            id="nilai_pengali"
            inset
            with-label
            label="Nilai Pengali"
            placeholder="2"
            type="text"
            currency
            v-model="multipier"
          />
        </div>
        <div class="sm:col-span-2">
          <base-input
            inset
            with-label
            label="Nilai Barang"
            placeholder="100.000"
            type="text"
            disabled
            :value="(amountAsNumber * multipier) | toCurrency"
          />
        </div>
        <div class="flex items-center gap-2 sm:col-span-2">
          <base-button :disabled="disabled">Simpan</base-button>
          <base-button
            type="button"
            color="white"
            :to="{ name: 'penjualan.topup.deposit-sc' }"
            >Kembali</base-button
          >
        </div>
      </form>

      <loading v-if="loading" />
    </base-card>
  </base-wrapper>
</template>

<script>
import dayjs from 'dayjs';
import SearchOfficeInput from '@/components/office/search-office-input.vue';
import { requestMixin } from '@/mixins/request/request';
import { mapActions } from 'vuex';

export default {
  components: { SearchOfficeInput },
  mixins: [requestMixin],
  data() {
    return {
      amount: null,
      date: dayjs().format('YYYY-MM-DD'),
      office: {
        id: null,
        code: null,
        originalCode: null,
        name: null,
      },
      loading: false,
      multipier: null
    };
  },
  computed: {
    disabled() {
      return !this.office.id || !this.amount;
    },
    amountAsNumber() {
      if (!this.amount) {
        return 0
      }

      return Number(this.amount.replace(/\D/gi, ''))
    }
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    async onSubmit() {
      this.loading = true;

      const [, err] = await this.request(
        `/api/v1/offices/${this.office.id}/-actions/top-up`,
        {
          method: 'post',
          data: {
            trx_type: 'deposit_balance',
            amount: this.amountAsNumber,
            multipier: this.multipier
          },
        }
      );

      if (err) {
        this.createAlert({
          status: 'error',
          data: this.getRequestErrorMessage(err),
        });
      } else {
        this.$router.push({ name: 'penjualan.topup.deposit-sc' });
      }

      this.loading = false;
    },
    async setDefaultMultipier () {
      this.loading = true

      const [res, err] = await this.request(`/api/v1/setups`, {
        params: {
          'filter[setup_key]': 'sc_deposit_multiplier',
          'fields[setups]': 'setup_value'
        }
      })

      if (!err && res.data.length) {
        this.multipier = res.data[0].attributes.setup_value
      }

      this.loading = false
    }
  },
  created() {
    this.setDefaultMultipier()
  }
};
</script>
