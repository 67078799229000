<template>
  <base-wrapper :loading="loading">
    <div class="space-y-4">
      <div class="flex justify-end gap-2">
        <base-input
          type="search"
          :shadow="false"
          placeholder="Cari Kode"
          debounce
          v-model="filter.search"
          @native-input="loadReports"
        />
        <base-select
          :expand="false"
          :shadow="false"
          :options="[
            { key: 'all', label: 'Status Laporan', value: null },
            { key: 'draft', label: 'Belum Diverifikasi', value: false },
            { key: 'valid', label: 'Diverifikasi Pusat', value: true },
          ]"
          v-model="filter.is_verified"
          @change="loadReports"
        />
      </div>

      <datatable
        :scroll-x="false"
        :columns="tableColumns"
        :total="reports.meta.page.total"
        :perPage="reports.meta.page.perPage"
        :currentPage="reports.meta.page.currentPage"
        @pagechanged="onPageChanged"
      >
        <template #tbody="{ classes }">
          <tr
            v-for="(report, index) in reports.data"
            :key="index"
            :class="[classes.tr, 'cursor-pointer bg-white hover:bg-green-100']"
            @click="onView(report)"
          >
            <td :class="classes.td">
              <p class="font-bold text-gray-900">
                {{ report.attributes.code }}
              </p>
            </td>
            <td :class="classes.td">
              {{ report.attributes.createdAt | formatDate }}
            </td>
            <td :class="classes.td">
              {{ report.attributes.name }}
            </td>
            <td :class="[classes.td, 'text-right']">
              {{ report.attributes.total_price | toCurrency }}
            </td>
            <td :class="[classes.td, 'text-right']">
              {{ report.attributes.total_payment | toCurrency }}
            </td>
            <td :class="[classes.td, 'text-center']">
              <base-badge
                :color="report.attributes.verified ? 'indigo' : 'yellow'"
                >{{
                  report.attributes.verified
                    ? 'Diverifikasi Pusat'
                    : 'Belum Diverifikasi'
                }}</base-badge
              >
            </td>
          </tr>
        </template>
      </datatable>
    </div>

    <view-modal
      :visible="viewModal.visible"
      :id="viewModal.id"
      status-badge-type="verification"
      :with-validation="false"
      @close="viewModal.visible = false"
    />
  </base-wrapper>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import { mapGetters } from 'vuex';
import ViewModal from '@/components/stock-loan-movement-report/view-modal.vue';

export default {
  mixins: [requestMixin],
  components: { ViewModal },
  data() {
    return {
      filter: {
        search: null,
        is_verified: null,
      },
      loading: false,
      reports: {
        data: [],
        meta: {
          page: {},
        },
      },
      viewModal: {
        visible: false,
        id: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser',
    }),
    tableColumns() {
      return [
        { id: 'code', name: 'No Penjualan' },
        { id: 'date', name: 'Tanggal' },
        { id: 'name', name: 'Nama Penjualan' },
        { id: 'price', name: 'Total Harga', theadClass: 'text-right' },
        { id: 'payment', name: 'Total Bayar', theadClass: 'text-right' },
        {
          id: 'report_status',
          name: 'Status Laporan',
          theadClass: 'text-center',
        },
      ];
    },
  },
  methods: {
    async loadReports(params) {
      this.loading = true;

      const [res, err] = await this.request(
        `/api/v1/stock-loan-movement-reports`,
        {
          params: {
            'page[size]': 5,
            'filter[is_valid]': true,
            'filter[is_verified]': this.filter.is_verified,
            'filter[search]': this.filter.search,
            'fields[stock-loan-movement-reports]':
              'code,createdAt,total_price,verified,name,total_payment',
            sort: '-createdAt',
            ...params,
          },
        }
      );

      if (!err) {
        this.reports = res;
      }

      this.loading = false;
    },
    onDeleted() {
      this.loadReports();

      this.viewModal.visible = false;
    },
    onPageChanged(page) {
      this.loadReports({
        'page[number]': page,
      });
    },
    onView(report) {
      this.viewModal.id = report.id;
      this.viewModal.visible = true;
    },
  },
  created() {
    this.loadReports();
  },
};
</script>
