<template>
  <base-card title="Saldo">
    <div class="space-y-4">
      <div
        v-for="(balance, index) in balanceUsed"
        :key="index"
        class="grid grid-cols-2 items-center"
      >
        <base-checkbox
          :name="balance.id"
          :label="`${balance.name} (${toCurrency(balance.balance)})`"
          :disabled="disabled || !balance.balance"
          v-model="balanceUsed[index].used"
          @uncheck="onUncheckBalance(index)"
        />
        <base-input
          :disabled="disabled || !balance.used"
          type="text"
          inset
          with-label
          currency
          label="Nominal"
          placeholder="100000"
          v-model="balanceUsed[index].amount"
          @change="onChangeAmount(index)"
        />
      </div>
    </div>
    <loading v-if="loading" />
  </base-card>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import BaseCheckbox from '@/components/base/BaseCheckbox.vue';
import { toCurrency } from '@/services/currency.service';
import { mapActions } from 'vuex';

export default {
  components: { BaseCheckbox },
  mixins: [requestMixin],
  props: {
    value: Array,
    paymentId: String,
    disabled: Boolean,
  },
  emits: ['input'],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    balanceUsed: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    toCurrency,
    async createPaymentMethod(balance) {
      this.loading = true;

      const [res, err] = await this.request('/api/v1/payment-methods', {
        method: 'post',
        data: {
          data: {
            type: 'payment-methods',
            attributes: {
              balance_used: balance.id,
              payment_amount: +balance.amount.replace(/\D/gi, ''),
            },
            relationships: {
              payment: {
                data: {
                  type: 'payments',
                  id: this.paymentId,
                },
              },
            },
          },
        },
      });

      if (err) {
        this.createAlert({
          data: this.getRequestErrorMessage(err),
          status: 'error',
        });
      }

      this.loading = false;

      return [res, err];
    },
    async updatePaymentMethod(balance) {
      this.loading = true;

      const [res, err] = await this.request(
        `/api/v1/payment-methods/${balance.paymentMethodId}`,
        {
          method: 'patch',
          data: {
            data: {
              type: 'payment-methods',
              id: balance.paymentMethodId,
              attributes: {
                payment_amount: +balance.amount.replace(/\D/gi, ''),
              },
            },
          },
        }
      );

      if (err) {
        this.createAlert({
          data: this.getRequestErrorMessage(err),
          status: 'error',
        });
      }

      this.loading = false;

      return [res, err];
    },
    onUncheckBalance(index) {
      this.balanceUsed[index].amount = null;
    },
    async onChangeAmount(index) {
      const balance = this.balanceUsed[index];

      const [res] = balance.paymentMethodId
        ? await this.updatePaymentMethod(balance)
        : await this.createPaymentMethod(balance);

      this.balanceUsed[index].paymentMethodId = res.data.id;
    },
  },
};
</script>
